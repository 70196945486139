import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { Button } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { SortOrderTypes } from '../../../../types/DatatableTypes';

interface CustomTableHeadCellProps extends TableCellProps {
  text?: string,
  columnName?: string,
  sortOrder?: SortOrderTypes,
  onSort?: (sortBy: string) => void
};

export function CustomTableHeadCell(props: CustomTableHeadCellProps) {
  const {text, columnName, sortOrder, onSort, ...tableCellProps} = props;

  const handleSort = () => {
    onSort && onSort(props.columnName!);
  };

  return (
    <TableCell {...tableCellProps}>
      {
      (props.onSort && !!props.columnName) ?
      (
        <>
          <Button sx={{cursor: 'pointer'}} onClick={() => handleSort()}>{props.text}
          {
            sortOrder && (sortOrder === 'asc' ? <SouthIcon sx={{width: 15}} /> : <NorthIcon sx={{width: 15}} />)
          }
          </Button>
        </>
      ):
      (
        props.text
      )}
  </TableCell>
  );
}