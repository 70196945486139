import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { GenericTooltip } from "./GenericTooltip";

export type TooltipPlacements = 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

type Props = {
  title: string | React.ReactNode;
  placement?: TooltipPlacements
};

export const WarningTooltip: React.FC<Props> = ({ title, placement }) => {
  return (
    <GenericTooltip title={title} placement={placement} icon={<InfoOutlinedIcon fontSize="inherit" sx={{ color: "#F44336" }} />} />
  );
};