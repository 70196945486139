import axios from "axios";
import {FeedbackPeriod, FeedbackPeriodRequest} from "../types/FeedbackPeriod";

export const retrieveFeedbackPeriods = async (isActive: boolean): Promise<FeedbackPeriod[]> => {
  return axios.get('/FeedbackPeriods', {params: {isActive: isActive}})
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const createNewFeedbackPeriod = async (body: FeedbackPeriodRequest): Promise<String> => {
  return axios.post('/FeedbackPeriods',body)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}

export const updateFeedbackPeriod = async (body: FeedbackPeriodRequest): Promise<String> => {
  return axios.put('/FeedbackPeriods', body)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}

export const deleteFeedbackPeriod = async (id: number): Promise<String> => {
  return axios.delete('/FeedbackPeriods/' + id)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}