import React, { ReactElement } from "react";
import { ClickAwayListener, IconButton, IconProps, Tooltip } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { isMobile } from 'react-device-detect';
import {TooltipPlacements} from "./WarningTooltip";

type Props = {
  title: string | React.ReactNode;
  placement?: TooltipPlacements;
  icon: ReactElement<IconProps>;
};

export const GenericTooltip: React.FC<Props> = (props) => {
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  return (
    isSmallScreen || isMobile ? <MobileGenericTooltip {...props} /> : <DesktopGenericTooltip {...props} />
  );
};

const DesktopGenericTooltip: React.FC<Props> = (props) => {
  const placement = props.placement ? props.placement : 'bottom';
  return (
    <Tooltip style={{ marginLeft: 3 }} title={props.title} placement={placement}>
      {props.icon}
    </Tooltip>
  );
};

const MobileGenericTooltip: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const placement = props.placement ? props.placement : 'bottom';

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip style={{ marginLeft: 3 }} title={props.title} open={open} placement={placement}>
        <IconButton onClick={() => setOpen(true)} size='small' sx={{ p: 0 }}>
          {props.icon}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

